<template>
    <depositoCrud></depositoCrud>
</template>

<script>
import depositoCrud from '@/components/Deposito/DepositoCrud';

export default {
    name: 'deposito',
    data() {
        return {};
    },
    components: {
        depositoCrud
    }
};
</script>
