<template>
    <v-card>
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ formTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="saveItem">Guarda</v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap dense>
                        <v-flex xs12 sm8>
                            <v-text-field v-model="item.nombre"
                                          label="Nombre del depósito">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm2>
                            <v-text-field v-model="item.abrv"
                                          label="Abrv">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm2>
                            <v-text-field v-model="item.capacidad"
                                          label="Capacidad">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field v-model="item.direccion"
                                          label="Dirección">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6>
                            <selectProvincia v-model="item.provincia.id"
                                             :texto="item.provincia.nombre"
                                             @texto="item.provincia.nombre = $event">
                            </selectProvincia>
                        </v-flex>

                        <v-flex xs12 sm6>
                            <selectLocalidad v-model="item.localidad.id"
                                             :texto="item.localidad.nombre"
                                             :provinciaId="item.provincia.id"
                                             @texto="item.localidad.nombre = $event">
                            </selectLocalidad>
                        </v-flex>
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>

    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import selectLocalidad from '@/components/Selects/selectLocalidad';
    import selectProvincia from '@/components/Selects/selectProvincia';

    export default {
        name: 'depositoDialogo',
        data() {
            return {
            };
        },

        computed: {
            ...mapState('deposito', ['item']),

            formTitle() {
                return this.index === -1 ? 'Nuevo' : 'Edita';
            }
        },

        methods: {
            ...mapActions('deposito', ['closeDialog', 'saveItem']),

            close() {
                this.closeDialog();
            }
        },

        created() { },

        components: {
            selectLocalidad,
            selectProvincia,
        }

    };
</script>
